import * as React from "react";

import SEO from "../components/seo";

import BlackHXOUSE from "../images/black-hxouse.png";
import HXOUSE from "../images/hxouse.png";
import Canada from "../images/canada.png";

import Animation from "../videos/animation.mp4";

const IndexPage = () => {
  return (
    <>
      <SEO />
      <video
        className="hidden sm:block absolute top-0 left-0 h-full w-full opacity-50"
        autoPlay
        playsInline
        loop
        muted
      >
        <source src={Animation} type="video/mp4" />
      </video>
      <main className="relative h-full w-full text-center flex justify-center">
        <div className="max-w-[800px] py-3 pb-6 sm:py-8 sm:pb-12 px-8 grid grid-cols-1 justify-center items-center">
          <h1 className="self-end sm:self-center z-10 font-header text-2xl sm:text-4xl md:text-6xl">
            BLACK ENTREPRENEURSHIP PROGRAM
          </h1>
          <div className="relative flex justify-center items-center sm:hidden">
            <video
              className="max-h-[150%] max-w-[150%] absolute justify-self-center"
              autoPlay
              playsInline
              loop
              muted
            >
              <source src={Animation} type="video/mp4" />
            </video>
            <video
              className="max-w-[75%] invisible"
              autoPlay
              playsInline
              loop
              muted
            >
              <source src={Animation} type="video/mp4" />
            </video>
          </div>
          <p className="z-10 font-body font-bold px-6 text-xs sm:text-base">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <a
            target="_blank"
            href="https://hxouse.slideroom.com/#/permalink/program/65734"
            rel="noreferrer"
            className="sm:self-end justify-self-center border-2 rounded-[12px] border-white font-header text-sm sm:text-xl uppercase w-max px-10 py-2 hover:bg-white hover:text-black"
          >
            Apply Now
          </a>
          <footer className="self-end flex justify-between w-full h-[15px] sm:h-[38px]">
            <div className="grow basis-0 flex">
              <img
                className="h-[15px] sm:h-[38px]"
                src={BlackHXOUSE}
                alt="Black HXOUSE Logo"
              />
            </div>
            <img src={Canada} alt="Canada Logo" />
            <div className="grow basis-0 flex justify-end">
              <img
                className="h-[15px] sm:h-[38px]"
                src={HXOUSE}
                alt="HXOUSE Logo"
              />
            </div>
          </footer>
        </div>
      </main>
    </>
  );
};

export default IndexPage;
